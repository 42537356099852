import { Models } from './model'
import { ViewerAPI } from '../types'
import { PlatformUtils, SdkInstance, PlatformLogger, Connection } from '@wix/thunderbolt-symbols'
import { CreateSetProps } from './setPropsFactory'
import { componentSdkFactoryArgs } from '@wix/thunderbolt-platform-types'
import { MasterPageId } from './constants'
import { InstanceCacheFactory } from './instanceCache'

export type SdkFactoryParams = {
	getSdkFactoryParams(args: { compId: string; controllerCompId: string; getChildren: () => Array<SdkInstance>; connection?: Connection; compType: string; role: string }): componentSdkFactoryArgs
}

export default function({
	models,
	viewerAPI,
	getCompRefById,
	platformUtils,
	createSdkHandlers,
	createSetProps,
	sdkInstancesCache
}: {
	models: Models
	viewerAPI: ViewerAPI
	getCompRefById: (compId: string) => any
	platformUtils: PlatformUtils
	createSdkHandlers: (pageId: string) => any
	logger: PlatformLogger
	createSetProps: CreateSetProps
	sdkInstancesCache: InstanceCacheFactory
}) {
	function getSdkFactoryParams({
		compId,
		getChildren,
		connection,
		compType,
		controllerCompId,
		role
	}: {
		compId: string
		controllerCompId: string
		getChildren: () => Array<SdkInstance>
		connection: Connection
		compType: string
		role: string
	}) {
		const props = models.propsModel[compId]
		const sdkData = models.platformModel.sdkData[compId]
		const handlers = createSdkHandlers(models.getPageIdByCompId(compId))
		const { hiddenOnLoad, collapseOnLoad } = models.platformModel.onLoadProperties[compId] || {}
		const onLoadProperties = {
			hidden: Boolean(hiddenOnLoad),
			collapsed: Boolean(collapseOnLoad)
		}

		function registerEvent<EventHandler extends Function = Function>(eventName: string, eventHandler: EventHandler) {
			handlers.registerEvent(compId, eventName, (args: any) => {
				if (args[0]) {
					args[0].target = sdkInstancesCache.getSdkInstance({ compId, controllerCompId, role })
				}
				eventHandler(...args)
			})
		}

		function getSdkInstance() {
			return sdkInstancesCache.getSdkInstance({ compId, controllerCompId, role })
		}

		const isGlobal = () => models.getPageIdByCompId(compId) === MasterPageId

		return {
			props,
			sdkData,
			compId,
			controllerCompId,
			setStyles: (style: object) => viewerAPI.updateStyles({ [compId]: style }),
			setProps: createSetProps(compId),
			compRef: getCompRefById(compId),
			handlers,
			getChildren,
			onLoadProperties,
			registerEvent,
			getSdkInstance,
			role,
			metaData: {
				compId,
				role,
				connection,
				compType,
				isGlobal,
				hiddenOnLoad: Boolean(hiddenOnLoad),
				collapsedOnLoad: Boolean(collapseOnLoad),
				isRendered: () => true, // TODO - replace this with real implementation
				getParent: () => null, // TODO - replace this with real implementation
				getChildren
			},
			...platformUtils
		}
	}
	return {
		getSdkFactoryParams
	}
}
